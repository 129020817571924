import React from "react";

function InventoryManagement() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            viewBox="0 0 50 50"
        >
            <path
                fill="#39A2A9"
                d="M50 50H24V24h26v26zm-24-2h22V26H26v22z"
            ></path>
            <path fill="#39A2A9" d="M26 50H0V24h26v26zM2 48h22V26H2v22z"></path>
            <path fill="#39A2A9" d="M26 26H0V0h26v26zM2 24h22V2H2v22z"></path>
            <path
                fill="#39A2A9"
                d="M18 11.41l-5-5-5 4.85V0h10v11.41zm-5-7.82l3 3V2h-6v4.52l3-2.93zM18 35.41l-5-5-5 4.85V24h10v11.41zm-5-7.82l3 3V26h-6v4.52l3-2.93zM42 35.4l-5-5-5 4.85V24h10v11.4zm-5-7.81l3 3V26h-6v4.52l3-2.93z"
            ></path>
        </svg>
    );
}

export default InventoryManagement;
