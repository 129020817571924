import React from "react";

function CustomerManagement() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="59"
            height="50"
            fill="none"
            viewBox="0 0 59 50"
        >
            <path
                fill="#39A2A9"
                d="M29.297 21.875A10.92 10.92 0 0118.36 10.937 10.92 10.92 0 0129.297 0a10.92 10.92 0 0110.937 10.937 10.92 10.92 0 01-10.937 10.938zm0-18.946c-4.394 0-8.008 3.613-8.008 8.009 0 4.394 3.613 8.008 8.008 8.008 4.394 0 8.008-3.613 8.008-8.008a7.982 7.982 0 00-8.008-8.009zM8.106 50A8.085 8.085 0 010 41.895a8.085 8.085 0 018.106-8.106 8.085 8.085 0 018.105 8.105C16.211 46.388 12.5 50 8.106 50zm0-13.281c-2.832 0-5.176 2.344-5.176 5.175 0 2.832 2.344 5.176 5.176 5.176 2.831 0 5.175-2.344 5.175-5.175 0-2.832-2.344-5.176-5.175-5.176zM29.297 50a8.085 8.085 0 01-8.105-8.105 8.085 8.085 0 018.105-8.106 8.085 8.085 0 018.106 8.105A8.086 8.086 0 0129.297 50zm0-13.281c-2.832 0-5.176 2.344-5.176 5.175 0 2.832 2.344 5.176 5.176 5.176 2.832 0 5.176-2.344 5.176-5.175 0-2.832-2.247-5.176-5.176-5.176zM50.684 50a8.085 8.085 0 01-8.106-8.105 8.085 8.085 0 018.106-8.106 8.085 8.085 0 018.105 8.105A8.085 8.085 0 0150.684 50zm0-13.281c-2.832 0-5.176 2.344-5.176 5.175 0 2.832 2.344 5.176 5.176 5.176 2.832 0 5.176-2.344 5.176-5.175 0-2.832-2.246-5.176-5.176-5.176z"
            ></path>
            <path
                fill="#39A2A9"
                d="M9.668 36.914c-.488 0-.879-.196-1.172-.586-.488-.684-.39-1.563.293-2.051l19.727-15.039c.684-.488 1.563-.39 2.051.293.489.684.39 1.563-.293 2.051L10.547 36.621c-.293.195-.586.293-.879.293z"
            ></path>
            <path
                fill="#39A2A9"
                d="M29.297 36.719c-.781 0-1.465-.684-1.465-1.465V20.41c0-.781.684-1.465 1.465-1.465s1.465.684 1.465 1.465v14.844c0 .781-.586 1.465-1.465 1.465z"
            ></path>
            <path
                fill="#39A2A9"
                d="M50.684 36.719c-.293 0-.586-.098-.879-.293L28.418 21.582c-.684-.489-.781-1.367-.39-2.051.488-.684 1.367-.782 2.051-.39l21.387 14.843c.684.489.781 1.367.39 2.051-.195.488-.684.684-1.172.684z"
            ></path>
        </svg>
    );
}

export default CustomerManagement;
