import React, { ReactElement } from "react";

import styles from "./styles.module.scss";
import SalesReporting from "../icons/SalesReporting";

import CustomerManagement from "../icons/CustomerManagement";

import InventoryManagement from "../icons/InventoryManagement";
import FeatureList from "@components/shared/featureList";

const illnesses = [
    {
        title: "Sales Insights",
        icon: <SalesReporting />,
        description:
            "Utilize the analyzed sales data to make better-informed business decisions.",
    },
    {
        title: "Customer Relations ",
        icon: <CustomerManagement />,
        description:
            "Keep track of purchases and stay connected with customers using marketing tools.",
    },
    {
        title: "Inventory Optimization",
        icon: <InventoryManagement />,
        description:
            "Simplify inventory management and gain valuable insights into logistic trends.",
    },
];

export default function WhyUs(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <FeatureList
                featureList={illnesses}
                mainTitle={
                    <>
                        Why Choose
                        <span className={`${styles["domain"]} `}>
                            PointOfSaleChoice.com
                        </span>
                        ?
                    </>
                }
                classes={{
                    mainSectionClasses: " mb-20 md:mb-[125px] text-center ",
                    mainTitleClasses: `mb-16 mt-24 text-xl sm:text-3xl sm:w-auto  mx-auto w-80 font-bold `,
                    innerSectionClasses: ` flex md:flex-row flex-col gap-10 md:gap-0 justify-center  center max-w-[] `,
                    oneBoxClasses: ` flex items-center flex-col px-10 ${styles["whyUsIcons"]}`,
                    iconClasses: "md:mb-8 mb-3 ",
                    titleClasses: "font-bold    text-xl ",
                    descriptionClasses: `${styles["paragraphColor"]} mt-[10px] max-w-[346px] text-base   `,
                }}
            />
            <button
                onClick={() => scrollToTop()}
                style={{ backgroundColor: "rgb(0, 86, 112" }}
                className={` text-white py-4 sm:px-11 px-4 text-sm sm:text-lg font-semibold rounded-lg block mx-auto mb-20 hover:-translate-y-2 hover:transition-all transition-all`}
            >
                Get My Free POS Quote Now
            </button>
        </>
    );
}
