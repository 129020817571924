import React from "react";

function SalesReporting() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            viewBox="0 0 50 50"
        >
            <path
                fill="#39A2A9"
                d="M24.73 46.237a1.075 1.075 0 01-1.075-1.076v-7.526a1.075 1.075 0 012.151 0v7.526a1.076 1.076 0 01-1.075 1.076zM24.73 6.452a1.075 1.075 0 01-1.075-1.075V1.075a1.076 1.076 0 012.151 0v4.302a1.076 1.076 0 01-1.075 1.075z"
            ></path>
            <path
                fill="#39A2A9"
                d="M33.333 50a1.073 1.073 0 01-.758-.317l-7.844-7.839-7.845 7.839a1.075 1.075 0 01-1.516-1.516l9.36-9.366 9.361 9.366A1.076 1.076 0 0133.333 50zM26.344 10.753h-1.075v10.215h10.215v-1.075a9.14 9.14 0 00-9.14-9.14zm1.075 8.064v-5.833a7.019 7.019 0 015.833 5.833H27.42z"
            ></path>
            <path
                fill="#39A2A9"
                d="M24.194 22.043V13.98h-1.076a9.14 9.14 0 109.14 9.14v-1.076h-8.064zm-1.076 8.065a6.99 6.99 0 01-1.075-13.898v7.983h7.984a6.986 6.986 0 01-6.909 5.915z"
            ></path>
            <path
                fill="#39A2A9"
                d="M49.462 38.71H0v-7.527h2.15v5.376h45.162V6.451H2.15v21.506H0V4.301h49.462V38.71z"
            ></path>
        </svg>
    );
}

export default SalesReporting;
